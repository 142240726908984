@import './../../theme';


/* PERSPECTIVE CONTROLS */

.perspective-controls {
    position: absolute;
    bottom: 20px;
    height: 50px;
    text-align: center;
    left: 50%;
    margin-left: -150px;
    width: 300px;
    // @include sm {
	// 	width: $sj-sm-container - 310px;
	// }
	// @include md {
	// 	width: $sj-md-container - 310px;
	// }
	// @include lg {
	// 	width: $sj-lg-container - 310px;
    // }
    span {
        width: 40px;
        height: 40px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 10px;
        border-radius: 3px;
        &:hover {
            background-color: $sj-light-gray;
        }
        &:active{
            background-color: $sj-gray;
        }
    }
    img {
        width: 40px;
        height: 40px;   
    }
    .tooltip {
        position: relative;
        display: inline-block;
      }
      
      .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        height: 20px;
        background-color: white;
        color: #000;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        top: -30px;
        left: -50px;
      }
      
      .tooltip:hover .tooltiptext {
        visibility: visible;
      }
}


#doors-btn {
    // background-image: url(./../static/viewIcn-doors@2x.png);
}
#front-btn {
    // background-image: url(./../static/viewIcn-front@2x.png);
}
#iso-btn {
    // background-image: url(./../static/viewIcn-iso@2x.png);
}
#side-btn {
    // background-image: url(./../static/viewIcn-side@2x.png);
}