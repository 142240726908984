/**
 * Print stylesheet for sanktjohanser.net
* @version         1.0
* @lastmodified    07.06.2020
*/

body {
	font-family: Roboto, sans-serif;
}

.din4 {
	margin: 20px auto;
	background: white;
	display: block;
	position: relative;
	width: 210mm;
	height: 296mm;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	break-after: always;
	font-size: 10pt;	
	text-transform: lowercase;
	.print_logo {
		position: absolute;
		top: 10mm;
		right: 10mm;
		margin-left: 0;
		margin-right: 0;
		img {
			width: 75mm;
		}
	}
	.threeView {
		position: relative;
		width: 100%;
		top: 16mm;
		height: 139mm;
		&::before {
			display: none;
		}
		&::after {
			display: none;
		}
		canvas {
			display: none;
		}
	}
	.print_preview {

		img {
			position: relative;
			width: 100%;
			left:0;
		}
		position: relative;
		height: 145mm;
		overflow: hidden;
	}
	.print_viewcaption {
		position: absolute;
		bottom: 130mm;
		width: calc(100% - 40mm);
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-left: 2cm;
		text-transform: uppercase;
	}
	.print_title {
		text-transform: uppercase;
		font-size: 12pt;
		font-weight: 900;
	}
	.description {
		position: absolute;
		bottom: 25mm;
		width: 170mm;
		text-align: left;
		margin-left: 2cm;
		.container {
			display: table;
			width: 100%;
			vertical-align: bottom;
		}
		.sub_title {
			text-transform: uppercase;
			font-weight: 900;
			margin-top: 10.5pt;
		}
		.col {
			display: table-cell;
			box-sizing: border-box; 
			vertical-align: top
		}	 
		.col:nth-child(n) { width: 50%; }
		
		.price_title {
			display:block; 
			text-align:left; 
			float:left;	
			text-transform: uppercase;
			font-weight: 900;
			margin-top: 19pt;
		}
		.price {
			font-weight: 900;
			margin-top: 19pt;
			display:block; 
			text-align:right;
		}
	}
	.left {
		 display:block; 
		 text-align:left; 
		 float:left;
	 }
	.right {
		 display:block; 
		 text-align:right;
	 }
	.print_footer {
		position: absolute;
		bottom: 10mm;
		width: 170mm;
		margin-left: 2cm;
	}
	.print_pagenumber {
		position: absolute;
		bottom: 5mm;
		width: 170mm;
		margin-left: 2cm;
	}
}
.din4:first-of-type {
	margin-top: 70px; 
  }
	

.print_controls {
  position: fixed;
  margin-left: -100px;
  top: 10px;
  left: 50%;
  width: 300px;
  text-align: center;
  .p_button {
    padding: 10px;
	border-radius: 3px;
	width: 100px;
    background-color: gray;
    margin: 5px;
    float:left;
	color: white;
	cursor: pointer;
	text-decoration: none;
    &:hover {
      background-color:#555;
    }
    &:active{
      background-color: #333;
    }
  }
}

@media print {
	.din4 {
		margin: 0;
		box-shadow: none;
	  }
	.din4:first-of-type {
		margin-top: 0; 
	  }
  	.print_controls{
		display: none;
	}

	@page {   
		margin: 0.0cm;
	}
}
