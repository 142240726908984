$sj-dark-gray: #2b2b2b;
$sj-gray: #8c8c8c;
$sj-light-gray: #f1f1f1;
$sj-pretty-white: #fefefe;
$sj-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
$sj-header-text: 29px;
$sj-paper-header: 29px;
$sj-default-text: 14px;
$sj-small-text: 14px;

// # BREAKPOINTS
// Small tablets (portrait view)
$screen-sm-min: 768px;
// Tablets and small desktops
$screen-md-min: 992px;
// Large tablets and desktops
// $screen-lg-min: 1200px;
$screen-lg-min: 1340px; //To compensate for height controls TODO could be smarter
// # HEIGHTS
$sj-sm-height: 560px;
$sj-md-height: 768px;
$sj-lg-height: 1024px;
// # DISPLAY CONTAINERS
$sj-sm-container: 720px;
$sj-md-container: 960px;
$sj-lg-container: 1140px;

$sj-outer-margin: 20px;

// # BEAKPOINT MIXINS
// Small devices
@mixin sm {
  @media (max-width: #{$screen-md-min}) {
      @content;
  }
}
// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}
// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}
// -> use like this
// @include sm {
//   padding: 0 20px;
// }