a {
  text-decoration: none;
  color: black;
}

.info-container {
  width: 300px;
  height: 96px;
  top: 150px;
  right: 0;
  position: absolute;
  text-align: left;
  line-height: 20px;

  span {
    // margin-right: 5px;
  }

  .info-box{
    .info-title {
      font-size:22px;
      font-weight: 500;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .material-container {
    }
    .size-container {
    }
  }

  .price-container {
    position:absolute;
    right: 0;
    top: 0;
    text-align: right;
    .price-amount {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .country-info {
      text-transform: uppercase;
    }
  }
}