@import './../../theme';

.footer-container {
	position: fixed;
	display: none;
	bottom: 0;
	left: 0;
	right: 0;
	height: 80px;
	background-color: $sj-dark-gray;
}

.footer-center-box {
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	text-align: left;
	color: $sj-pretty-white;
	width: 920px;
	@include sm {
		width: $sj-sm-container;
	}
	@include md {
		width: $sj-md-container;
	}
	@include lg {
		width: $sj-lg-container;
	}
}

.footer-big-text {
	font-size: $sj-header-text;
	position: absolute;
	top: 7px;
}
.footer-small-text {
	font-size: $sj-small-text;
	position: absolute;
	top: 47px;
}

.footer-name {
	left: 0;
	font-weight: 500;
}
.footer-size {
	left: 185px;
}
.footer-material {
}
.footer-price {
	right: 0;
}
.footer-netto {
	right: 0;
}
