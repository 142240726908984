@import 'theme';

// can be used for windowResize
body {
	&::before {
		content: 'sm';
		display: none;
	}
	@include sm {
		&::before {
			content: 'sm'
		}
	}
	@include md {
		&::before {
			content: 'md'
		}
	}
	@include lg {
		&::before {
			content: 'lg'
		}
	}
}

.App {
	text-align: center;
	width: 100%;
	height: 100%;
}

.center-box {
	// width: 960px;
	// height: 768px;
	margin-left: $sj-outer-margin;
	margin-right: $sj-outer-margin;
	position: relative;
	height: 100%;
	// border: 1px solid black;
	// @include sm {
	// 	width: $sj-sm-container;
	// }
	// @include md {
	// 	width: $sj-md-container;
	// }
	// @include lg {
	// 	width: $sj-lg-container;
	// }
}

.logo-box {
	position: absolute;
	right: 0;
	top: 55px;
}

#sj-shelf-container {
	position: absolute;
	left: 0;
	right: 300px;
	top:0;
	bottom:0;
}

.threeView {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	min-height: 560px;
	&::before {
		content: '';
		position: absolute;
		background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.463158) 32.81%, rgba(255, 255, 255, 0) 95.83%);
		width: 30px;
		height: 100%;
		display: block;
	}
	&::after {
		content: '';
		position: absolute;
		background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.463158) 32.81%, rgba(255, 255, 255, 0) 95.83%);
		width: 30px;
		height: 100%;
		right: 0;
		top: 0;
		display: block;
	}
}

canvas {
	display: block;
	width: 100%;
	height: 100%;
	// border: 1px solid orange;
}

canvas:focus {
	outline: 0;
}
