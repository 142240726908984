@import './../../theme';


// ------------ HORIZONTAL ------------
.hSize {
	position: absolute;
	bottom: 100px;
	left: 0;
	right: 300px;
	& .MuiButton-root {
			text-transform: lowercase;
	}
}

.hSize-val {
	position: relative;
	top: 10px;
}
// ---------- HORIZONTAL END ----------
// ------------ VERTICAL ------------
.vContainer {
	position: absolute;
	height: 100%;
	width: 90px;
	& .MuiButton-root {
			text-transform: lowercase;
	}
}
.vSize-box {
	position: absolute;
	vertical-align: middle;
	padding-right: 10px;
	margin-top: -11px;
	height: 22px;
}
.vSize-val {
	margin-right: 10px;
}
// ------------ VERTICAL END ------------
// ------------ DEPTH ------------
.dSize {
	position: absolute;
	right: 300px;
	top: 480px;
    & .MuiButton-root {
        text-transform: lowercase;
    }
}
.dSize-val {
    left: -12px;
}
.dSize-line {
	position: absolute;
	top: 15px;
	right: 90px;
	width: 30px;
	height: 1px;
	transform: rotate(45deg);
	background-color: black;
}
// ------------ DEPTH END ------------